


















































import ICrudClient from "@/lib/ICrudClient";
import { Activity } from "@/data/models/Activity";
import DataProvider from "@/lib/DataProvider";
import EntitySelect from "@/components/EntitySelect.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import EnumForm from "@/components/forms/EnumForm.vue";
@Component({
  components: {
    FormContainer,
    EntitySelect,
    Loader,
  },
})
export default class ActivitiesForm extends Vue {
  private enumForm = EnumForm;
  @Prop()
  public id!: string;
  @Prop()
  public provider!: DataProvider<Activity>;
  @Prop()
  public crud!: ICrudClient<Activity>;
  private item: any = null;
  mounted() {
    if (this.id === "new") {
      this.item = {
        name: "",
      };
    }
  }
}
